@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: none;
  outline: none;
  font-family: 'Inter', sans-serif;
}
input, button {
  font-family: 'Inter', sans-serif;
}

h1, h2, h3 {
  font-weight: normal;
}
h1 {
  text-align: center;
}

body.zen {
  button {
    display: none;
  }
  .info {
    display: none;
  }
  .team {
    margin-top: 6rem;
  }
  h1 {
    display: none;
  }
  .teamName {
    border-color: white;
  }
}

* {
  // outline: 1px solid red;
}

input {
  border-color: #ccc;
  outline-color: #ccc;
}

.App {
  padding: 0;
  h1 {
    padding: 1rem;
    margin: 0;
    box-shadow: 0 1px 10px 2px hsl(0deg 0% 69% / 40%);
  }
}

#wrapper {
  display: flex;
  margin-top: 3rem;
  justify-content: space-between;
  align-items: center;
}
.timer {
  border: 2px solid #ccc;
  padding: 0.5rem;
  width: 10rem;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 40%;
  font-size: 3rem;
  border-radius: 10px;
  z-index: 2;
  background-color: #fff;
  font-family: 'Roboto Mono', monospace;
}
.buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}
button {
  font-size: 2rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  border: 1px solid #ccc;
  border-radius: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ddd;
  }
  &:active {
    transform: scale(0.95);
  }
}

#theme, #clues {
  button {
    margin-left: auto;
    margin-right: auto;
  }
}
.themeinput {
  width: 3rem;
  font-size: 2rem;
  border: none;
  outline: none;

  &:last-child {
    text-align: center;
    width: 100%;
  }
}
.divider {
  height: 60vh;
  width: 2px;
  background-color: #ccc;
}
.team {
  width: 50%;
  padding: 1.25rem 8rem;
  text-align: center;
  margin-top: -4rem;
  flex-direction: column;
  display: flex;
  align-items: center;

  .score {
    text-align: center;
    font-size: 16rem;
    margin: 4rem;
    width: 100%;
    border: none;
    outline: none;
    line-height: 16rem;
    margin: 3rem;
    font-weight: bold;
    font-family: 'Roboto Mono', monospace;
  }
  .teamName {
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
    border: 2px solid #ccc;
    text-align: center;
    padding: 0.5rem;
    width: 100%;
    border-radius: 10px;
    outline: none;
  }
}

#helppopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background-color: #fff;
  width: 80%;
  box-shadow: 0 1px 10px 2px hsl(0deg 0% 69% / 40%);
  padding: 1rem;
  border-radius: 10px;
  border: 2px solid #ccc;

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 4px;
    width: 50%;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }
}
#packetinfo, #roundinfo {
  margin-top: 1rem;
  font-size: 1.5rem;
  user-select: none;
  cursor: pointer;
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #666;
}
#helpbutton {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
}
#github {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
#x {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}
#announce {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 80%;
  background-color: white;
  border: 2px solid #ccc;
  padding: 0;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 10px 2px hsl(0deg 0% 69% / 40%);
  align-items: center;
  
  input {
    font-size: 3.5rem;
    text-align: center;
    outline: none;
    width: 100%;
    border: none;
  }
  button {
    width: auto;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  h2 {
    text-align: center;
  }
}

#gander img {
  position: absolute;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 15rem;
  height: 15rem;
  border-radius: 10px;
  box-shadow: 0 1px 10px 2px hsl(0deg 0% 69% / 40%);
  opacity: 0;
  transition: opacity 2s ease;
}
.show {
  opacity: 1 !important;
  transition: opacity 2s ease;
  z-index: 98 !important;
}